import React, {useContext, useEffect} from 'react';
import statsIcon from '../../assets/icons/stats-icon.png'
import csvIcon from '../../assets/icons/csv-icon.png'
import flagIcon from '../../assets/icons/flag-icon.png'
import cupIcon from '../../assets/icons/cup-icon.png'
import predictionIcon from '../../assets/icons/prediction-icon.png'
import searchIcon from '../../assets/icons/search-icon.png'
import './joinPremium.css'
import axios from "axios";
import {Link, useLocation, useNavigate} from "react-router-dom";
import premiumIcon from '../../assets/icons/crown.png'
import {AppContext} from "../../context/AppContext";

function JoinPremium(props) {
    const {i18String, geo, lang} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    const userToken = localStorage.getItem('user-token');
    const userDetail = localStorage.getItem('user');
    const user = JSON.parse(userDetail);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    /*FOR CLIENT TYPE*/
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile/i.test(userAgent);
    const clientType = isMobile ? 'WEB_MOBILE' : 'WEB';

    const handleCheckout = async () => {
        if (!userToken) {
            navigate('/sign-in?redirect=join-premium')
        } else {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'x-platform': 'FootyAssist',
                    'x-client-type': clientType,
                    'x-language': lang
                },
            };

            const responseData = await fetch(`https://santra24.6stats.com/api/v1/payment/create-checkout-session`, requestOptions)
                .then(async (response) => {
                    const responseObject = await response.json();
                    console.log('responseObject', responseObject);
                    if (responseObject.data.checkoutUrl) {
                        window.location.href = responseObject.data.checkoutUrl;
                    }
                })
                .catch((err) => console.log(err.message));
        }
    };

    const premiumFutures = [
        {
            title: `${i18String('Unlimited Access')}`,
            text: `${i18String('No limits. View all stats, pages, and leagues without restrictions.')}`,
            src: statsIcon,
            id: 1
        },
        {
            title: `${i18String('Football Stats')}`,
            text: `${i18String('Easy-to-use source for football stats including player, referee, team, and league stats.')}`,
            src: flagIcon,
            id: 2
        },
        {
            title: `${i18String('Goal, Cards & Corner Stats')}`,
            text: `${i18String('Highly-profitable markets, and we have all the stats to go with it.')}`,
            src: predictionIcon,
            id: 3
        },
        {
            title: `${i18String('Football Player Stats')}`,
            text: `${i18String('Football Player Stats is a resource for player match stats displayed in a unique and digestible format.')}`,
            src: searchIcon,
            id: 4
        },
        {
            title: `${i18String('Referee Stats')}`,
            text: `${i18String('Referee stats such as Cards give per match, penalties, fouls, red cards and home advantage.')}`,
            src: csvIcon,
            id: 5
        },
        {
            title: `${i18String('2000+ Competitions')}`,
            text: `${i18String('Gain access to thousands of profitable leagues from all corners of the globe.')}`,
            src: cupIcon,
            id: 6
        }

    ]

    return (
        <div>
            <div className='const-width premium-section'>
                <div className='premium-container'>
                    <div className='top-premium-info-container'>
                        <div>
                            <div className='premium-top-left-container'>
                                <h4 className='fv-premium-title'>Footy Assist Premium</h4>
                                <p className='unlock-everything-text'>{i18String('Unlock Everything.')}</p>
                                <span className='get-access-text'>{i18String('Get access to all of our premium features now and maximize your betting profits. Welcome to Premium.')}</span>
                            </div>
                        </div>
                        <div className='limited-price-section'>
                            {
                                userToken && user.premium && user.premium.status === 'active' ?
                                    <div className='join-premium-payment-container'>
                                        <img src={premiumIcon} alt="Premium"/>
                                        <span className='already-premium-text'>{i18String('You have already Premium.')}</span>
                                    </div>
                                    :
                                    <div className='join-premium-payment-container'>
                                        <div className='price-container'>
                                            <span className='price'>{geo && geo.countryCode === 'TR' ? '£9.99' : '£14.99'} <span className='month-text'> / Month</span></span>
                                        </div>
                                        <div className='premium-button-container'>
                                            <button onClick={() => handleCheckout()} className='join-premium-button'>
                                                {i18String('Join Premium')}
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                    <div className='features-and-benefits-section'>
                        <div className='features-lists-container'>
                            {
                                premiumFutures.map(item => {
                                    return (
                                        <div className='flag-and-title-general-container'>
                                            <div className='flag-and-title-container'>
                                                <img className='join-premium-flag' src={item.src} alt=""/>
                                                <h5 className='features-title'>{item.title}</h5>
                                            </div>
                                            <span className='features-text'>{item.text}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JoinPremium;
