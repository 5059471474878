import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    fetchLeagueStandings,
    fetchMatchDetail,
    fetchMatchDetailStatistic,
    fetchTeamDetail,
    fetchTimeLine
} from "../../api";
import './MatchDetail.css';
import dayjs from "dayjs";
import {Tab, Tabs} from "@mui/material";
import MatchDetailsStatsEvent from "./stats-event/MatchDetailsStatsEvent";
import MatchInfo from "./match-info/MatchInfo";
import Compare from "./compare/Compare";
import MatchDetailsStatistics from "./statistics/MatchDetailsStatistics";
import MatchDetailsStandings from "./standings/MatchDetailsStandings";
import StadiumIcon from '../../assets/icons/stadium.png';
import whistle from '../../assets/icons/whistle.png';
import noTeamLogo from "../../assets/icons/noTeamLogo.png";
import {AppContext} from "../../context/AppContext";
import matchPlusIcon from '../../assets/icons/match-plus.png';
import DocumentMeta from 'react-document-meta';
import {matchDetailsMeta} from "../../SEO/SeoMetaTags";
import Lineups from "./lineups/Lineups";
import PlayerStats from "./player-stats/PlayerStats";
import Referee from "./referee/Referee";
import phone1 from '../../../src/assets/icons/mobile-pohone-qr-1.png';
import phone2 from '../../../src/assets/icons/mobile-pohone-qr-2.png';
import directQR from '../../assets/icons/direct-QR.png';
import MatchPlus from "./match-plus/MatchPlus";
import {getMatchFromLocalStorage} from "../local-storage/ManageLocalStorage";

function MatchDetails(props) {
    const {lang, timeZone, i18String} = useContext(AppContext);

    const params = useParams();
    const navigate = useNavigate();
    const matchTeamsDetail = getMatchFromLocalStorage(`teams-state:${params?.id}`);

    const [matchDetailTeamData, setMatchDetailTeamData] = useState(null);
    const [matchDetailData, setMatchDetailData] = useState(null);
    const [selectedTabType, setSelectedTabType] = useState(params.tabType ? params.tabType : "h2h");
    const [timeLineData, setTimeLineData] = useState(null);
    const [statisticData, setStatisticData] = useState(null);
    const [standingsData, setStandingsData] = useState(null);
    const [eventData, setEventData] = useState("");
    const hideElement = params.tabType === 'player-stats' || params.tabType === 'referee' || params.tabType === 'match-plus';
    const statsButtons = [
        {text: `${i18String('Match Info')}`, type: 'Match Info', header: 'matchInfo', tabType: 'match-info'},
        {text: `${i18String('Lineups')}`, type: 'Lineups', header: 'lineups', tabType: 'lineups'},
        {text: `${i18String('H2H')}`, type: 'H2h', header: 'compare', tabType: 'h2h'},
        {text: `${i18String('Goals')}`, type: 'Goal', header: 'goalStatsHeader', tabType: 'goals'},
        {text: `${i18String('Corners')}`, type: 'Corner', header: 'cornerStatsHeader', tabType: 'corners'},
        {text: `${i18String('Cards')}`, type: 'Card', header: 'cardsStatsHeader', tabType: 'cards'},
        {
            text: `${i18String('Player Stats')}`,
            type: 'PlayerStats',
            header: 'playerStatsHeader',
            tabType: 'player-stats'
        },
        {text: `${i18String('Referee')}`, type: 'referee', header: 'refereeHeader', tabType: 'referee'},
        {text: `${i18String('Match Plus')}`, type: 'matchPlus', header: 'matchPlusHeader', tabType: 'match-plus'},
    ];

    useEffect(() => {
        fetchMatchDetail(params.id).then(data => {
            setMatchDetailData(data.data.data);
        })

        fetchTimeLine(params.id).then(data => {
            setTimeLineData(data.data.data);
        })

        fetchMatchDetailStatistic(params.id).then(data => {
            setStatisticData(data.data.data[0]?.groups);
        })

        fetchLeagueStandings(matchTeamsDetail?.seasonId).then(data => {
            setStandingsData(data.data.data.standings)
        })

        const interval = setInterval(() => {
            fetchMatchDetail(params.id).then(data => {
                setMatchDetailData(data.data.data);
            })
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (matchDetailData === null) {
            return;
        }

        if (!params.tabType) {
            if (matchDetailData?.status === 'Fixture') {
                navigate(`/match-detail/${params.id}/h2h`);
                setSelectedTabType('h2h');
            } else {
                navigate(`/match-detail/${params.id}/match-info`);
                setSelectedTabType('match-info');
            }
        } else {
            if (params.tabType === 'goals') {
                setEventData('Goal');
            } else if (params.tabType === 'corners') {
                setEventData('Corner');
            } else if (params.tabType === 'cards') {
                setEventData('Card');
            } else {
                setEventData(params.type);
            }
        }
    }, [matchDetailData])

    useEffect(() => {
        fetchTeamDetail(params.id, eventData).then(data => {
            const matchDetail = data.data.data;
            setMatchDetailTeamData(matchDetail);
        });
    }, [eventData]);

    const match = [];
    if (matchDetailTeamData !== null) {
        matchDetailTeamData.forEach((data, index) => {
            const {name} = data;
            const stats = [];
            data.stats.forEach(stat => {
                stats.push({
                    ...stat,
                });
            });
            match.push({
                title: {
                    name,
                },
                data: stats,
            });
        });
    }

    const startTime = (value) => {
        return dayjs(value).tz(timeZone).format("HH:mm");
    }

    const matchDateTime = (value) => {
        return dayjs(value).tz(timeZone).format("DD.MM.YYYY HH:mm")
    }

    const clickTab = (event) => {
        navigate(`/match-detail/${params.id}/${event.tabType}`);
        setSelectedTabType(event.tabType);
        setEventData(event.type);
    }

    const subTabIndex = statsButtons.findIndex((st) => st.tabType === selectedTabType);

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const ff = (tabs) => {
        if (tabs.src) {
            return <img style={{width: '100%', height: '30px'}} src={matchPlusIcon} alt="Match Plus"/>
        } else {
            return tabs.text
        }
    }

    return (
        <DocumentMeta {...matchDetailsMeta(matchDetailData)[lang]}>
            <div className='match-details-section'>
                <div className={'const-width-content match-details-general-section'}>
                    <div className='match-detail-section'>
                        <div className='match-details-general-column-container' style={{padding: '10px'}}>
                            <div className={'stats-tabs-and-teams-section'}>
                                {/*MATCH LEAGUE, ROUND AND TIME SECTION*/}
                                {
                                    matchDetailData &&
                                    <div className='match-detail-league-round-and-time-section'>
                                        <a
                                            target={"_blank"}
                                            href={`/league/${matchDetailData.area.toLowerCase()}-${matchDetailData.league.toLowerCase().replace(/\s+/g, '-')}/${matchDetailData.leagueId}/${matchDetailData.seasonId}/stats`}
                                            className='match-detail-area-info-container'>
                                            <img className="match-detail-area-logo"
                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${matchDetailData.areaId}.png`}
                                                 alt=""/>
                                            <a className='match-detail-area-text'> {matchDetailData.area}</a>
                                            <a className='match-detail-league-text'> - {matchDetailData.league}</a>

                                        </a>

                                        <div className='date-time-container'>
                                    <span
                                        className='match-detail-date-time'>{matchDateTime(matchDetailData.dateTimeUtc)}</span>
                                        </div>
                                    </div>
                                }

                                {/*MATCH TEAMS SECTION*/}
                                <div className={'web-teams-container'}>
                                    <div className={'match-details-team'}>
                                        {
                                            matchTeamsDetail &&
                                            <div className='match-detail-teams-general-section'>
                                                <a target={"_blank"} href={`/team-detail/${matchTeamsDetail.homeId}`}
                                                   className='match-details-team-container'>
                                                    <img className="match-detail-teams-logo"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={matchTeamsDetail.home}/>
                                                    <a target={"_blank"}
                                                       href={`/team-detail/${matchTeamsDetail.homeId}`}
                                                       className='match-detail-team-name match-detail-team-name-for-home'>
                                                        {matchTeamsDetail.home}
                                                    </a>
                                                </a>
                                                <div className='match-detail-coach-container-for-home'>
                                                    {
                                                        matchDetailData &&
                                                        <span
                                                            className='match-detail-coach-text'>{matchDetailData.hcoach}</span>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {/*<div className='match-detail-last-5-match-container'>
                                            <span className='match-detail-last-5-match-text'>Son 5 Maç : </span>
                                            <div className='match-detail-last-5-match-result-container'>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={drawIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={loseIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                            </div>
                                        </div>*/}
                                    </div>
                                    {
                                        matchDetailData &&
                                        <div className='match-detail-match-info-container'>
                                            {
                                                matchDetailData.status === 'Fixture' &&
                                                <div className='match-start-time-container'>
                                                <span
                                                    className='match-detail-start-time'>{startTime(matchTeamsDetail?.time)}</span>
                                                    <span className='match-versus-V'>V</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Played' &&
                                                <div className='match-detail-played-match-container'>
                                                    <span className='match-detail-ms'>MS</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Playing' && matchDetailData.st !== 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <div className='match-detail-match-time'>{matchDetailData.min}'
                                                    </div>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.st === 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <span className='match-detail-match-time'>HT</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className={'match-details-team'}>
                                        {
                                            matchTeamsDetail &&
                                            <div className='match-detail-teams-general-section'>
                                                <a target={"_blank"} href={`/team-detail/${matchTeamsDetail?.awayId}`}
                                                   className='match-details-team-container'>
                                                    <a target={"_blank"}
                                                       href={`/team-detail/${matchTeamsDetail?.awayId}`}
                                                       className='match-detail-team-name match-detail-team-name-for-away'>
                                                        {matchTeamsDetail?.away}
                                                    </a>
                                                    <img className="match-detail-teams-logo"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.awayId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={matchTeamsDetail?.away}/>
                                                </a>

                                                <div className='match-detail-coach-container-for-away'>
                                                    {
                                                        matchDetailData &&
                                                        <span
                                                            className='match-detail-coach-text'>{matchDetailData.acoach}</span>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {/*<div className='match-detail-last-5-match-container-away'>
                                            <span className='match-detail-last-5-match-text'>Son 5 Maç : </span>
                                            <div className='match-detail-last-5-match-result-container'>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={loseIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={loseIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={drawIcon} alt="Win"/>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>

                                {/*FOR MOBILE*/}
                                <div className={'mobile-teams-container'}>
                                    <div className={'match-details-team'}>
                                        <img
                                            onClick={() => {
                                                window.open(`/team-detail/${matchTeamsDetail.homeId}`)
                                            }}
                                            className="match-detail-teams-logo"
                                            src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.homeId}.png`}
                                            onError={(e) => onImageError(e)}
                                            alt={matchTeamsDetail?.home}/>
                                        <a
                                            target={"_blank"} href={`/team-detail/${matchTeamsDetail?.homeId}`}
                                            className={'match-detail-team-name'}>{matchTeamsDetail?.home}</a>
                                        <div className='match-detail-coach-container-for-home'>
                                            {
                                                matchDetailData &&
                                                <span
                                                    className='match-detail-coach-text'>{matchDetailData.hcoach}</span>
                                            }
                                        </div>
                                    </div>
                                    {
                                        matchDetailData &&
                                        <div className='match-detail-match-info-container'>
                                            {
                                                matchDetailData.status === 'Fixture' &&
                                                <div className='match-start-time-container'>
                                                <span
                                                    className='match-detail-start-time'>{startTime(matchTeamsDetail?.time)}</span>
                                                    <span className='match-versus-V'>V</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Played' &&
                                                <div className='match-detail-played-match-container'>
                                                    <span className='match-detail-ms'>MS</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Playing' && matchDetailData.st !== 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <span
                                                        className='match-detail-match-time'>{matchDetailData.min}'</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.st === 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <span className='match-detail-match-time'>HT</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className={'match-details-team'}>
                                        {/*<div
                                    onClick={() => {
                                        window.open(`/team-detail/${matchTeamsDetail.awayId}`)
                                    }}
                                    className='match-details-team-container'>

                                </div>*/}
                                        <img
                                            onClick={() => {
                                                window.open(`/team-detail/${matchTeamsDetail?.awayId}`)
                                            }}
                                            className="match-detail-teams-logo"
                                            src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.awayId}.png`}
                                            onError={(e) => onImageError(e)}
                                            alt={matchTeamsDetail?.away}/>
                                        <a
                                            target={"_blank"} href={`/team-detail/${matchTeamsDetail?.awayId}`}
                                            className={'match-detail-team-name'}>{matchTeamsDetail?.away}</a>
                                        <div className='match-detail-coach-container-for-away'>
                                            {
                                                matchDetailData &&
                                                <span
                                                    className='match-detail-coach-text'>{matchDetailData.acoach}</span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/*MATCH VENUE AND REFEREE SECTION*/}
                                {
                                    matchTeamsDetail?.venue || matchTeamsDetail?.referee ||
                                    <div className='match-detail-venue-and-referee-container'>
                                        <div className='match-detail-venue-container'>
                                            <img className='match-detail-stadium-icon' src={StadiumIcon} alt="Stadium"/>
                                            <span
                                                className='match-detail-venue-city'><b>{matchDetailData?.venue?.venueCity}</b> </span>
                                            <span
                                                className='match-detail-venue'> - {matchDetailData?.venue?.venue}</span>
                                        </div>

                                        <div className='referee-info-section'>
                                            {
                                                matchDetailData?.ref &&
                                                <div className='match-detail-referee-container'>
                                                    <img className='match-detail-whistle-icon' src={whistle}
                                                         alt="Referee"/>
                                                    <img
                                                        style={{margin: '0 5px'}}
                                                        className="match-detail-area-logo"
                                                        src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${matchDetailData.ref.countryId}.png`}
                                                        alt=""/>
                                                    <a className='referee-name-match-detail'
                                                       href={`/referee/${matchDetailData?.ref?.id}/season/${matchDetailData?.seasonId}`}>
                                                        {matchDetailData?.ref?.name}
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='match-detail-empty-section'>
                                <div style={{marginLeft: '10px'}}>
                                    <div style={{
                                        background: 'linear-gradient(to bottom, #485b74, #3e2d56)',
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        borderRadius: '4px',
                                    }}>
                                        <div>
                                            <div style={{
                                                paddingLeft: '10px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '10px 0 10px 15px'
                                            }}>
                                                <span style={{
                                                    fontSize: '14px',
                                                    color: '#fff',
                                                    fontWeight: '600',
                                                    margin: '10px 0'
                                                }}>Uygulamayı mobil cihazına şimdi indir!</span>
                                            </div>

                                            <div style={{
                                                paddingLeft: '10px',
                                                overflow: 'hidden',     // İçeriğin alt yarısını kesmek için
                                                height: '178px',        // Kapsayıcı yüksekliği, yalnızca yarısı görünür olacak
                                            }}>
                                                <div>
                                                    <img
                                                        style={{height: '243px'}}
                                                        src={phone1}
                                                        alt="An internal screen from the FootyAssist app"
                                                    />
                                                    <img
                                                        style={{height: '220px'}}
                                                        src={phone2}
                                                        alt="An internal screen from the FootyAssist app"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{padding: '0 10px', display: 'grid', placeItems: 'center'}}>
                                            <div style={{display: 'grid', justifyContent: 'center', height: '100%'}}>
                                                <ul style={{
                                                    padding: '0',
                                                    margin: 0,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <li style={{listStyleType: 'none'}}><a
                                                        href="https://apps.apple.com/gb/app/footyassist-football-tables/id6476205503"
                                                        target="_blank" rel="noopener">
                                                        <div className="app-store-platform-image"></div>
                                                    </a></li>
                                                    <li style={{listStyleType: 'none'}}><a
                                                        href="https://stats-365.com/android"
                                                        target="_blank" rel="noopener">
                                                        <div className="play-store-platform-image"></div>
                                                    </a></li>
                                                </ul>

                                                <div
                                                    style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                                                    <img style={{height: '100px', width: '100px'}}
                                                         src={directQR}
                                                         alt="The frame of a mobile phone"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={params.tabType === 'player-stats' ? 'match-details-tabs-section-full-width' : 'match-details-tabs-section'}>
                            <Tabs
                                variant={"scrollable"}
                                scrollButtons="auto"
                                TabIndicatorProps={{style: {display: "none"}}}
                                value={subTabIndex}
                            >
                                {
                                    statsButtons.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            className={'match-details-tab'}
                                            label={ff(tab)}
                                            onClick={() => clickTab(tab)}
                                            style={{
                                                color: subTabIndex === index ? '#3e2d56' : '#485b74' // Aktif sekme siyah, diğerleri varsayılan
                                            }}
                                        />
                                    ))
                                }
                            </Tabs>
                        </div>


                        <div className='match-details-content-section'>
                            <div className='match-details-general-column-container'>
                                <div
                                    className={params.tabType === 'player-stats' || params.tabType === 'match-plus' ? 'web-match-details-content-section-full-width' : 'web-match-details-content-section'}>
                                    {/*MATCH TABS SECTION*/}
                                    {
                                        params.tabType === 'match-info' &&
                                        <MatchInfo matchTeamsDetail={matchTeamsDetail}
                                                   lang={lang}
                                                   timeLineData={timeLineData}
                                                   matchDetailData={matchDetailData}
                                        >

                                        </MatchInfo>
                                    }
                                    {
                                        params.tabType === 'lineups' &&
                                        <Lineups matchTeamsDetail={matchTeamsDetail}
                                                 lang={lang}
                                                 matchId={params.id}
                                                 i18String={i18String}
                                        >
                                        </Lineups>
                                    }
                                    {
                                        params.tabType === 'h2h' &&
                                        <Compare matchTeamsDetail={matchTeamsDetail}
                                                 lang={lang}
                                                 matchId={params.id}
                                                 i18String={i18String}
                                        ></Compare>
                                    }
                                    {
                                        params.tabType === 'player-stats' &&
                                        <PlayerStats
                                            matchTeamsDetail={matchTeamsDetail}
                                            lang={lang}
                                            matchId={params.id}
                                            i18String={i18String}
                                        ></PlayerStats>
                                    }
                                    {
                                        params.tabType === 'referee' &&
                                        <Referee
                                            matchDetailData={matchDetailData}
                                            i18String={i18String}
                                        ></Referee>
                                    }
                                    {
                                        (params.tabType === 'goals' ||
                                            params.tabType === 'corners' ||
                                            params.tabType === 'cards') && (
                                            <MatchDetailsStatsEvent
                                                matchDetailTeamData={matchDetailTeamData}
                                                matchTeamsDetail={matchTeamsDetail}
                                                i18String={i18String}
                                            />
                                        )
                                    }
                                    {
                                        params.tabType === 'match-plus' &&
                                        <MatchPlus matchTeamsDetail={matchTeamsDetail}></MatchPlus>
                                    }
                                </div>

                                <div className='statistics-and-standings-section'
                                     style={{display: hideElement && 'none'}}>
                                    <MatchDetailsStatistics
                                        lang={lang}
                                        statisticData={statisticData}
                                        i18String={i18String}
                                    >
                                    </MatchDetailsStatistics>
                                    <MatchDetailsStandings
                                        statisticData={statisticData}
                                        matchDetailData={matchDetailData}
                                        lang={lang}
                                        matchTeamsDetail={matchTeamsDetail}
                                        standingsData={standingsData}
                                    >
                                    </MatchDetailsStandings>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}

export default MatchDetails;
