import React, {useContext, useState} from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {AppContext} from "../../context/AppContext";

function SignUp(props) {

    const {clientType, lang} = useContext(AppContext);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setErrors] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const validateFields = () => {
        let valid = true;
        const newErrors = {};

        if (!name) {
            newErrors.name = lang === 'tr' ? 'Lütfen isminizi giriniz.' : 'Please enter Name.';
            valid = false;
        }

        if (!username) {
            newErrors.username = lang === 'tr' ? 'Lütfen kullanıcı adınızı giriniz.' : 'Please enter User Name.';
            valid = false;
        }

        if (!email) {
            newErrors.email = lang === 'tr' ? 'Lütfen Mail adresinizi giriniz.' : 'Please enter Email.';
            valid = false;
        }

        if (!password) {
            newErrors.password = lang === 'tr' ? 'Lütfen şifrenizi giriniz.' : 'Please enter Password.';
            valid = false;
        } else if (password !== confirmPassword) {
            newErrors.confirmPassword = lang === 'tr' ? 'Şifre ve şifre onaylama eşleşmiyor.' : 'Password and Confirm Password does not match.';
            valid = false;
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = lang === 'tr' ? 'Lütfen şifrenizi doğrulayınız.' : 'Please enter Confirm Password.';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const register = async (event) => {
        event.preventDefault();
        if (validateFields()) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-platform': 'FootyAssist',
                    'x-client-type': clientType,
                    'x-language': lang
                },
                body: JSON.stringify({
                    name,
                    username,
                    email,
                    password
                })
            };
            const response = await fetch('https://santra24.6stats.com/api/user/register', requestOptions);
            const responseObject = await response.json();
            if (responseObject.success) {
                window.location.href = '/sign-in';
            }
        }
    };

    const handleInputChange = (setter, field) => (e) => {
        setter(e.target.value);
        setErrors((prev) => ({ ...prev, [field]: '' }));
    };

    return (
        <div className={'general-auth-section'}>
            <Container component="main" maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 6,
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        <div>
                            {
                                lang === 'tr' ?
                                    <span>Kayıt Ol</span>
                                    :
                                    <span>Sign Up</span>
                            }
                        </div>
                    </Typography>
                    <Box component="form" onSubmit={register} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="name"
                            label={lang === 'tr' ? 'Ad Soyad' : 'Name Surname'}
                            id="name"
                            autoComplete="name"
                            autoFocus
                            onChange={handleInputChange(setName, 'name')}
                        />
                        {error.name && <span className='register-error-text'>{error.name}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="username"
                            label={lang === 'tr' ? 'Kullanıcı Adı' : 'User Name'}
                            id="username"
                            autoComplete="username"
                            onChange={handleInputChange(setUsername, 'username')}
                        />
                        {error.username && <span className='register-error-text'>{error.username}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={lang === 'tr' ? 'Email Adresi' : 'Email Address'}
                            name="email"
                            autoComplete="email"
                            onChange={handleInputChange(setEmail, 'email')}
                        />
                        {error.email && <span className='register-error-text'>{error.email}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={lang === 'tr' ? 'Şifre' : 'Password'}
                            type="password"
                            id="password"
                            value={password}
                            autoComplete="current-password"
                            onChange={handleInputChange(setPassword, 'password')}
                        />
                        {error.password && <span className='register-error-text'>{error.password}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label={lang === 'tr' ? 'Şifreyi Onayla' : 'Confirm Password'}
                            type="password"
                            value={confirmPassword}
                            id="confirmPassword"
                            onChange={handleInputChange(setConfirmPassword, 'confirmPassword')}
                        />
                        {error.confirmPassword && <span className='register-error-text'>{error.confirmPassword}</span>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            style={{background: '#1a3150'}}
                        >
                            {
                                lang === 'tr' ?
                                    <span>Kayıt Ol</span>
                                    :
                                    <span>Sign Up</span>
                            }
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default SignUp;
